<template>
	<el-row class="main_MyPay">
		<el-row class="main_MyPay-content">
			<el-col style="border-bottom: 1px solid #ccc; height: 72px; line-height: 72px; text-indent: 30px;"> <span style="color:blue;font-weight:bold;overflow: hidden;"></span>提现</el-col>
			
			<el-col style="line-height: 48px;color: rgb(160 159 159);font-size: 12px;text-align: left; text-indent: 20px;">
				当前可提现金币{{goldcoin}}，折算成人民币¥{{goldcoin/10}}（10金币=1人民币，满1000金币起提）
			</el-col>
			<el-form :model="form" style="width: 810px; padding-left: 20px;">
				<!--<el-form-item label="金额" :label-width="formLabelWidth">-->
				<!--<el-input v-model="form.money" autocomplete="off"></el-input>-->
				<!--</el-form-item>-->
				<el-row>
					<el-col :span="11">
						<el-form-item  :label-width="formLabelWidth">
							<el-input v-model="form.name" autocomplete="off"><template slot="prepend"><span style="color: red"></span>姓名</template></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11" :push="2">
						<el-form-item :label-width="formLabelWidth">
							<el-input v-model="form.identity_card" autocomplete="off"><template slot="prepend"><span style="color: red"></span>身份证号</template></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				
				
				<el-form-item :label-width="formLabelWidth">
					<el-input v-model="form.bank_card" autocomplete="off"><template slot="prepend"><span style="color: red"></span>银行卡号</template></el-input>
				</el-form-item>
				<el-form-item :label-width="formLabelWidth">
					<el-input v-model="form.bank_name" autocomplete="off"><template slot="prepend"><span style="color: red"></span>开户行</template></el-input>
				</el-form-item>
				<el-form-item :label-width="formLabelWidth">
					<el-row>
						<el-col :span='9'>
							<el-input v-model="form.mobile" autocomplete="off"><template slot="prepend"><span style="color: red"></span>手机号</template></el-input>
						</el-col>
						<el-col :span='6' :push="1">
							<el-button @click='verification' style="color: #c43d1d;">点击发送验证码</el-button>
						</el-col>
						<el-col :span='9'>
							<el-form-item :label-width="formLabelWidth">
								<el-input v-model="form.verification_value" autocomplete="off"><template slot="prepend"><span style="color: red"></span>验证码</template></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
				</el-form-item>
				<el-form-item :label-width="formLabelWidth">
					<el-input @keyup.native="keyup()" v-model="form.money" autocomplete="off"><template slot="prepend"><span style="color: red"></span>提现金额</template></el-input>
				</el-form-item>
				<!--<el-form-item label="身份证号码" :label-width="formLabelWidth">-->
				<!--<el-input v-model="form.identity_card" autocomplete="off"></el-input>-->
				<!--</el-form-item>-->
			
			</el-form>
			<el-col style="line-height: 30px;padding-left: 30px;color: rgb(160 159 159); font-size: 12px; margin-bottom: 76px;">
				提现说明：<br>
				1、充值金币不可提现，收入金币需满1000方可提现（¥10金币=1元），每次提现至少满¥100元。<br>
				2、每月累计提现最多¥800元。<br>
				3、申请提现后需等待管理员手动审核处理，处理时间为一周之内。<br>
				4、为保证提现资金正常到账，请确认银行账号、姓名（与身份证一致）。<br>
				5、<span>如有疑问请电话联系我们:400-182-1204 转2。</span><br>
			</el-col>
			<el-button @click="tixian" style="width: 150px; height: 40px; margin-top: 76px; margin:0 auto; display: block; color: #c43d1d;">提 现</el-button>
		</el-row>
	</el-row>

</template>


<script>
	import {
		requestSend_sms,
		requestCheck_sms,
		requestStorage,
		requestWithdrawal_add
	} from "../api/request";

	export default {
		data() {
			return {
				form: {
					name: '',
					identity_card: '',
					bank_card:null,
					bank_name:null,
					mobile:null,
					money: 0,
					verification_value:null
				},
				formLabelWidth: '0px',
				goldcoin:''
			}
		},
		methods: {
			verification(){
				requestSend_sms(this.form.mobile).then(res=>{
					if(res.code==0){
						this.$message({
						    message:'发送成功' ,
						    type: 'success'
						});
					}
				})
			},
			tixian() {
				let _this = this,
					data = {};
				data = this.form;
				if (data.alipay_name == "" || data.account_ID == "" || data.mobile == "") {
					this.$message.error('请填写全部信息');
				} else if (data.money < 100) {
					this.$message.error('每次提现至少满¥100元');
				} else if (Number(data.money) > Number(this.goldcoin)) {
					this.$message.error('您的金币不足');
				} else {
					data.money = data.money / 10;
					
					let datas={
						mobile:_this.form.mobile,
						code:_this.form.verification_value
					}
					requestCheck_sms(datas).then(res=>{
						if(res.code==0){
							console.log(data)
							requestWithdrawal_add(data).then(res => {
								if (res.code == 0) {
									_this.dialogFormVisible = false;
									_this.$message({
										message: '恭喜你提现成功',
										type: 'success'
									});
									history.go(0)
								}
							})
						}
					})
				}
			},
			keyup() {
				this.form.money = this.form.money.replace(/[^\.\d]/g, '');
				this.form.money = this.form.money.replace('.', '');
			}
		},
		mounted() {
			requestStorage().then(res => {
				localStorage.setItem("storage", JSON.stringify(res.ret));
				this.goldcoin = this.Account.GetStorage().resource_goldcoin;
				this.allgoldcoin = this.Account.GetStorage().goldcoin;
			})
		}
	}
</script>


<style scoped>
	.main_MyPay {
		background: #f8f8f8;
		height: 1000px;
		padding-top: 25px;
	}

	.main_MyPay-content {
		width: 1395px;
		margin: 0 auto;
		height: 837px;
		background: white;
		box-shadow:0px 0px 6px #c4c4c4;
		border-radius: 5px;
	}

	.das {
		width: 150px;
		height: 60px;
		line-height: 60px;
	}


	.active::before {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		border: 11px solid #009843;
		border-top-color: transparent;
		border-left-color: transparent;
	}
	.active{
		border: 2px solid #009843;
	}
	.active::after {
		content: '';
		width: 1px;
		height: 5px;
		position: absolute;
		right: 3px;
		bottom: 4px;
		border: 2px solid #fff;
		border-top-color: transparent;
		border-left-color: transparent;
		transform: rotate(45deg);
	}
	/deep/.el-dialog__header{
		text-align: center;
	}
	>>>.el-button.is-plain:focus, .el-button.is-plain:hover{
		border: 2px solid #009843;
	}
	
	>>>.el-button{
		border-color: #c33e1b;
	}
	
</style>
